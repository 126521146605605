import { Component } from "react"
import { Container ,Grid, Typography} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'
import { css } from '@emotion/react'

// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
//ICONS MATERIAL UI


const justifyCss = css` 
  text-align: justify;      
 `    
 
class CreditReportCmp extends Component {    

    render() {
        const {            
            titleIcon=<span><strong>Autorización de informe de crédito</strong><br/></span>,
            txtTitle=<span><strong>Español</strong><br/><br/>Al marcar la casilla y continuar con el proceso de solicitud, “usted”: 
                                    <ol>
                                        <li> Certifica que la información que proporcionó a Apoyo Financiero, Inc. por teléfono es precisa y completa y tiene la intención de que “nosotros” confiemos en ella (Apoyo Financiero, Inc.) para juzgar la solvencia.</li>
                                        <li> Nos autoriza a conservar como propiedad nuestra esta información y todos los materiales relacionados, ya sea que se otorgue crédito o no.</li>
                                        <li> Certificar que el préstamo no se utilizará para ningún propósito ilegal.</li>
                                        <li> Nos autoriza a nosotros y a nuestros afiliados a obtener informes crediticios del consumidor e investigaciones de su historial crediticio y laboral en relación con esta solicitud y cualquier actualización, revisión, recopilación o modificación del crédito otorgado y a responder cualquier pregunta sobre nuestra experiencia crediticia con usted. de conformidad con la ley aplicable y nuestra política de privacidad.</li>
                                        <li> Acepta notificarnos por escrito sobre los cambios de nombre, dirección y otra información proporcionada anteriormente.</li>
                                        <li> Para otros fines relacionados. Usted comprende que, después de evaluar su solicitud completa y verificar su informe, podemos decidir no ofrecerle crédito o productos (incluida la apertura de una cuenta de ahorros). TAMBIÉN PUEDES SOLICITAR UNA COPIA DIGITAL A TU REPRESENTANTE DE APOYO FINANCIERO.</li>
                                    </ol>  <br/><br/></span>,
            txtContent=<span><strong>English</strong><br/><br/>By checking the box and continuing with the application process, “you”:
                                     <ol>
                                        <li>Certify that the information you provided Apoyo Financiero, Inc. over the telephone to be accurate and complete and intend it to be relied upon by “us” (Apoyo Financiero, Inc.) to judge creditworthiness.</li>
                                        <li>Authorize us to retain as our property this information and all related materials whether or not credit is extended.</li>
                                        <li>Certify that the loan will not be used for any illegal purpose.</li>
                                        <li>Authorize us and our affiliates to obtain consumer credit reports and investigations of your credit and employment history in connection with this application and any update, review, collection or modification of the credit extended and to answer any questions about our credit experience with you consistent with applicable law and our privacy policy</li>
                                        <li>Agree to notify us in writing of changes in name, address, and other information provided above</li>
                                        <li>For other related purposes. You understand that, after evaluating your complete application and checking your report, we may decide not to offer credit or products (including opening a savings account) to you. YOU MAY ALSO REQUEST A DIGITAL COPY FROM YOUR APOYO FINANCIERO REPRESENTATIVE.</li>
                                     </ol>  <br/><br/></span>,
            txtTitleEmp='',
                    
            
                    } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>
                        <Typography component='h3' variant="h4" >
                        <strong style={{color:'#698d21'}}>{titleIcon}</strong>                        
                        </Typography>
                        <Typography component='h3' variant="h6" >
                            <strong style={{color:'#e66728'}}>{txtTitleEmp}</strong>                        
                        </Typography>
                        
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CardElevation>
                    <Typography component='subtitle' variant="subtitle" css={justifyCss}>
                        {txtTitle}                       
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" css={justifyCss}>
                        {txtContent}                        
                    </Typography>
                    <br/>                                       
                    </CardElevation>
                    <br/><br/>
                    
                </Grid>
                
                </Grid>
            </Container>

        )
    }
}

CreditReportCmp.propTypes = {      
    titleIcon: PropTypes.string, 
    txtTitle: PropTypes.string, 
    txtContent: PropTypes.string, 
    txtTitleEmp: PropTypes.string,
    
    
    txtContentTwo: PropTypes.string, 
        
}

export default withThemeProps(CreditReportCmp, 'VDPNCreditReport')






